import { css, ThemeContext } from "@emotion/react";
import { theme } from "../../../theme/default";
import { getBreakpoint } from "../../../utils/utils";

const breakM = getBreakpoint(theme.breakpoints.m);
const breakS = getBreakpoint(theme.breakpoints.s);

const getStyles = () =>
  css({
    width: "100%",
    height: "40rem",
    display: "grid",
    placeItems: "center",
    "&>.footer": {
      background: theme.colors.backgroundLight,
      padding: "12rem 8rem",
      display: "grid",
      // gridTemplateColumns: ".8fr 1fr .8fr",
      gridTemplateColumns: "1fr 1fr",
      placeItems: "center",
      gridGap: "4rem",
      [breakM]: {
        padding: "3rem 2rem",
        gridTemplateRows: "1fr 1fr",
        gridTemplateColumns: "1fr",
        gridGap: "2rem",
      },
      img: {
        width: "100%",
      },
      ".form": {
        display: "grid",
        color: theme.colors.white,
        placeItems: "center",
        textAlign: "center",
        fontFamily: "primary",
        border: `.5rem solid ${theme.colors.primary}`,
        padding: "3rem",
        gridGap: "2rem",
        p: { margin: 0 },
        h1: {
          textTransform: "uppercase",
          span: { color: theme.colors.primary },
        },
        input: {
          width: "100%",
          height: "3rem",
          fontSize: "1.8rem",
        },
      },
      ".social": {
        display: "grid",
        gridTemplateRows: "1fr 1fr",
        placeItems: "center",
        h1: {
          color: theme.colors.white,
          margin: 0,
          fontSize: "5rem",
          fontFamily: "title",
        },
        "& div": {
          "& img": {
            width: "5rem",
            margin: "1rem",
          },
        },
      },
    },
    ".legal": {
      width: "100%",
      background: theme.colors.primary,
      display: "grid",
      gridAutoFlow: "column",
      fontSize: "1.8rem",
      color: theme.colors.white,
      fontFamily: "primary",
      padding: "2rem 0rem",
      gridGap: "3rem",
      a: {
        cursor: "pointer",
        "&:first-child": {
          placeSelf: "end",
        },
      },
    },
  });

export default getStyles;
