/** @jsx jsx */
import React, { useEffect, useRef } from "react";
import { SerializedStyles, jsx } from "@emotion/react";
import getStyles from "./Main.styles";
import { css } from "@emotion/react";
import { theme } from "../../../theme/default";

export const Main = () => {
  const styles: SerializedStyles = getStyles();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    videoRef.current && videoRef.current.play();
  }, []);
  return (
    <div css={styles} id="main">
      {/* <video
        ref={videoRef}
        src={theme.videos.backgroundVideoLoop}
        autoPlay
        loop
      /> */}

      <video autoPlay muted loop id="myVideo">
        <source src={theme.videos.backgroundVideoLoop} type="video/mp4" />
      </video>
      <img src={theme.images.logos.arrowfistBig} />
    </div>
  );
};
