/** @jsx jsx */
import { Global, jsx, SerializedStyles } from "@emotion/react";
import getStyles from "./App.styles";
import getGlobalStyles from "./Global.styles";
import Home from "./pages/Home/Home";

function App() {
  const globalStyles = getGlobalStyles();
  const styles: SerializedStyles = getStyles();
  return (
    <div className="app" css={styles}>
      <Global styles={globalStyles} />
      <Home />
    </div>
  );
}

export default App;
