/** @jsx jsx */
import React from "react";
import { SerializedStyles, jsx } from "@emotion/react";
import getStyles from "./Trailer.styles";
import { css } from "@emotion/react";
import { theme } from "../../../theme/default";

export const Trailer = () => {
  const styles: SerializedStyles = getStyles();
  return (
    <div css={styles} id="trailer">
      <div className="container-info">
        <img src={theme.images.logos.deflector} />
        <p>
          A <span>nonlinear roguelike</span> game with a unique combat mechanic,
          based on
          <span> deflecting</span>, and the ability to mix <span>DNA</span> to
          create whatever skill you want.
        </p>
      </div>
      <iframe
        src="https://cdn.akamai.steamstatic.com/steam/apps/256853904/movie480_vp9.webm?t=1634290510"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};
