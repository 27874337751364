/** @jsx jsx */
import { SerializedStyles, jsx } from "@emotion/react";
import React from "react";
import { theme } from "../../theme/default";
import getStyles from "./Header.style";

interface Item {
  label: string;
  href?: string;
  target?: string;
  action?: () => void;
}

export const Header = () => {
  const style: SerializedStyles = getStyles();

  const menuItems: Item[] = [
    { label: "Deflector", href: "#mechanics" },
    { label: "Gallery", href: "#gallery" },
    { label: "Contact", href: "#contact" },
    { label: "Press Kit", href: "https://drive.google.com/drive/folders/1JFWELGP7Rtrc6QVU81X8SpfjOkYKYjh3?usp=sharing", target: "_blank" },
  ];

  return (
    <div css={style}>
      <img src={theme.images.logos.arrowfistWhite} alt="logo" />
      <nav>
        {menuItems.map((item: any) => (
          <a target={item.target} href={item?.href} onClick={item?.action}>
            {item.label}
          </a>
        ))}
      </nav>
    </div>
  );
};
