/** @jsx jsx */
import { jsx } from "@emotion/react";
import { DiscordButton, Header } from "../../components";
import { Footer, Gallery, Main, Mechanics, Trailer } from "./../Home";

function App() {
  return (
    <div>
      <Main />
      <Trailer />
      <Mechanics />
      <Gallery />
      <Footer />

      <Header />
      <DiscordButton />
    </div>
  );
}

export default App;
