import { css } from "@emotion/react";
import { theme } from "../../../theme/default";
import { getBreakpoint } from "../../../utils/utils";

const breakpointS = getBreakpoint(theme.breakpoints.s);
const breakpointM = getBreakpoint(theme.breakpoints.m);

const getStyles = () =>
  css({
    width: "100%",
    height: "100%",
    position: "relative",
    video: {
      position: "relative",
      width: "100%",
      height: "100%",
      objectFit: "cover",
      opacity: 0.6,
    },

    img: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      [breakpointM]: {
        width: "70%",
      },
      [breakpointS]: {
        width: "50%",
      },
    },
  });

export default getStyles;
