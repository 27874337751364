/** @jsx jsx */
import React from "react";
import { SerializedStyles, jsx } from "@emotion/react";
import getStyles from "./Mechanics.styles";
import { css } from "@emotion/react";
import { theme } from "../../../theme/default";

interface Mechanic {
  title: string;
  description: string;
  image: string;
}

export const Mechanics = () => {
  const styles: SerializedStyles = getStyles();

  const Mechanics: Mechanic[] = [
    {
      title: "Deflect",
      description:
        "Deflecting with the right timing allows you to deflect almost everything in the game, from enemy bullets and your own weapons, to some enemies itself.",
      image: theme.images.mechanics.deflect,
    },
    {
      title: "Non-Linear",
      description:
        "No linear levels anymore! Choose in which order to complete any of the worlds available in the game. But choose carefully, some quests may require a specific order to be completed.",
      image: theme.images.mechanics.nonLinear,
    },
    {
      title: "Combine skills",
      description:
        "Combine skills based and create new ones based on your playstyle. Think twice before placing them on the merging table, as the skills may vary and affect others based on where it is placed.",
      image: theme.images.mechanics.combineSkills,
    },
  ];

  return (
    <div css={styles} id="mechanics">
      <div className="round_background">Mechanics</div>
      <div className="container-mechanics">
        <div className="draw-line"></div>
        {Mechanics.map((mechanic: Mechanic) => (
          <div className="container-mechanic">
            <div className="info">
              <h1>{mechanic.title}</h1>
              <p>{mechanic.description}</p>
            </div>
            <img src={mechanic.image} alt="mechanic image" />
          </div>
        ))}
      </div>
    </div>
  );
};
