/** @jsx jsx */
import React from "react";
import { SerializedStyles, jsx } from "@emotion/react";
import getStyles from "./Gallery.styles";
import data from "./../../../resources/data";
import { Carrusel } from "../../../components/Carrusel/Carrusel";

export const Gallery = () => {
  const styles: SerializedStyles = getStyles();

  return (
    <div css={styles} id="gallery">
      <div className="draw-line"></div>
      <div className="round_background">Gallery</div>
      <Carrusel images={data.galleryImages} />
    </div>
  );
};
