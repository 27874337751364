/** @jsx jsx */
import { SerializedStyles, jsx } from "@emotion/react";
import React from "react";
import { theme } from "../../theme/default";
import getStyles from "./DiscordButton.style";

export interface ButtonDiscord {
  className?: string;
}

export const DiscordButton = (props: ButtonDiscord) => {
  const style: SerializedStyles = getStyles();

  const onClickButton = () => {
    window.open("https://discord.gg/tDMMThF5NG");
  };

  return (
    <button
      onClick={onClickButton}
      className={`${props.className} round_background`}
      css={style}
    >
      Join Discord <img src={theme.images.logos.discord} alt="discord logo" />
    </button>
  );
};
