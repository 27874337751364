/** @jsx jsx */
import { SerializedStyles, jsx } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";
import { theme } from "../../theme/default";
import { useScroll } from "../../utils/useScroll"; 
import getStyles from "./Carrusel.style";

export interface Carrusel {
  images?: string[];
}

export const Carrusel = (props: Carrusel) => {
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [imageSize, setImageSize] = useState<number>(0);
  const containerImagesRef = useRef<HTMLDivElement>(null);
  const width = useScroll();

  const style: SerializedStyles = getStyles({
    currentImage,
    imageSize,
    imagesNum: props.images?.length as number,
  });

  useEffect(() => {
    containerImagesRef?.current &&
      props.images &&
      setImageSize(
        containerImagesRef?.current?.scrollWidth / props.images?.length
      );
  }, [containerImagesRef?.current?.scrollWidth, currentImage, width]);

  const getCurrentImageClassName = (index: number): string => {
    if (currentImage === index) return "current image";
    else return "image";
  };

  const onClickImage = (id: number) => {
    return () => {
      setCurrentImage(id);
    };
  };

  return (
    <div css={style}>
      <div className="container-images" ref={containerImagesRef}>
        {props.images?.map((image: string, index: number) => (
          <img
            src={image}
            className={getCurrentImageClassName(index)}
            alt={`carrusel-image-${index}`}
            onClick={onClickImage(index)}
          />
        ))}
      </div>
    </div>
  );
};
