import { css } from "@emotion/react";
import { theme } from "../../theme/default";
import { getBreakpoint } from "../../utils/utils";

const breakS = getBreakpoint(theme.breakpoints.s);

interface StyleProps {
  currentImage: number;
  imageSize: number;
  imagesNum: number;
}

const getImagePosition = (props: StyleProps): number => {
  return props.imageSize * props.currentImage;
};

const transitionTime = "0.4s";

const getStyles = (props: StyleProps) =>
  css({
    height: "65rem",
    transition: transitionTime,
    marginBottom: "10rem",
    [breakS]: {
      marginBottom: "0rem",
      height: "35rem",
    },
    ".container-images": {
      transform: `translate(${props.imageSize / 2}px, 0)`,
      display: "grid",
      gridAutoFlow: "column",
      height: "100%",
      position: "relative",
      placeItems: "center",
      right: `${getImagePosition(props)}px`,
      gridTemplateColumns: `repeat(${props.imagesNum}, minmax(50%, 25rem))`,
      transition: transitionTime,
      ".image": {
        opacity: "0.4",
        position: "relative",
        top: "-8rem",
        cursor: "pointer",
        transition: transitionTime,
        width: "130%",
      },
      ".current": {
        opacity: "1",
        zIndex: 5,
        top: 0,
        transform: "scale(1.2) translateY(2rem)",
      },
    },
  });

export default getStyles;
