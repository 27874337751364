import { css } from "@emotion/react";
import { theme } from "./theme/default";

const getStyles = () =>
  css`
    html {
      background: ${theme.colors.background};
      /* background-image: url("./resources/img/BackgroundIlustration.jpg"); */
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center;
      scroll-behavior: smooth;
      overflow-x: hidden;
    }

    html,
    body,
    #root {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      font-family: BAHN_regular;
      font-size: 10px;
      user-select: none;
    }

    p {
      color: ${theme.colors.white};
      font-family: primary;
      font-size: 1.8rem;
      span {
        color: ${theme.colors.primary};
      }
    }

    @font-face {
      font-family: title;
      src: url(${theme.fonts.title});
    }
    @font-face {
      font-family: primary;
      src: url(${theme.fonts.primary});
    }

    .round_background {
      border-radius: 50%;
      width: 25rem;
      height: 25rem;
      font-family: title;
      font-size: 4rem;
      line-height: 4rem;

      color: ${theme.colors.white};
      border: 0.5rem solid ${theme.colors.discordButton.border};
      background: ${theme.colors.discordButton.background};
      box-shadow: 0 0 1.5rem ${theme.colors.discordButton.border},
        inset 0 0 1.5rem ${theme.colors.discordButton.border},
        inset 0 0 0 1.5rem ${theme.colors.discordButton.background},
        inset 0 0 0 2.5rem ${theme.colors.discordButton.shadowDark};
      transition: 0.2s;
    }

    @media (max-width: ${theme.breakpoints.s}) {
      .round_background {
        font-size: 2rem;
        width: 15rem;
        height: 15rem;
      }
    }
  `;
export default getStyles;
