import { useState } from "react";

export const useScroll = () => {
  const [width, setWidth] = useState<number>(0);

  window.addEventListener("scroll", (e: any) => {
    setWidth(window.innerWidth);
  });

  return width;
};
