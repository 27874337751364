import { css } from "@emotion/react";
import { theme } from "../../theme/default";
import { getBreakpoint } from "../../utils/utils";

const breakpointM = getBreakpoint(theme.breakpoints.m);
const breakpointS = getBreakpoint(theme.breakpoints.s);

const getStyles = () =>
  css({
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    background: theme.colors.header.background,
    position: "fixed",
    top: 0,
    width: "100%",
    img: {
      width: "22rem",
      height: "auto",
      [breakpointM]: {
        width: "18rem",
      },
      [breakpointS]: {
        width: "10rem",
      },
    },
    nav: {
      width: "fit-content",
      placeSelf: "center end",
      fontFamily: "title",
      height: "auto",
      placeItems: "center",
      display: "grid",
      marginRight: "5.5rem",
      gridTemplateColumns: "repeat(4, 1fr)",
      gridGap: "7rem",
      fontSize: "2rem",
      [breakpointM]: {
        fontSize: "1.8rem",
        gridGap: "2rem",
      },
      [breakpointS]: {
        fontSize: ".8rem",
        gridGap: ".5rem",
      },
      a: {
        color: theme.colors.white,
        textDecoration: "none",
      },
    },
  });

export default getStyles;
