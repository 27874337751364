import { css, ThemeContext } from "@emotion/react";
import { theme } from "../../../theme/default";
import { getBreakpoint } from "../../../utils/utils";

const breakM = getBreakpoint(theme.breakpoints.m);
const breakS = getBreakpoint(theme.breakpoints.s);

const getStyles = () =>
  css({
    width: "100%",
    height: "auto",
    maxWidth: "150rem",
    margin: "auto",
    display: "grid",
    placeItems: "start center",
    paddingTop: "9rem",
    ".round_background": {
      position: "relative",
      display: "grid",
      placeItems: "center",
      color: theme.colors.white,
      border: `.5rem solid ${theme.colors.discordButton.border}`,
      background: theme.colors.background,
      boxShadow: `inset 0 0 1.5rem ${theme.colors.background}, inset 0 0 0 1.25rem ${theme.colors.background}, inset 0 0 0 2rem ${theme.colors.backgroundDark}`,
      transition: ".2s",
      "&:after, &:before": {
        content: "''",
        position: "absolute",
        width: "20vw",
        right: "calc(50% + 13rem)",
        borderTop: `.5rem solid ${theme.colors.primary}`,
      },
      "&:before": {
        left: "calc(50% + 13rem)",
        borderRight: "1rem solid transparent",
        [breakS]: {
          left: "calc(50% + 8rem)",
        },
      },
      "&:after": {
        right: "calc(50% + 13rem)",
        borderLeft: "1rem solid transparent",
        [breakS]: {
          right: "calc(50% + 8rem)",
        },
      },
    },
    ".container-mechanics": {
      background: theme.colors.primary,
      ".draw-line": {
        height: "10rem",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: ".5rem",
        [breakM]: {
          gridGap: "0",
        },
        "&:before, &:after": {
          background: theme.colors.background,
          content: "''",
        },
      },
      ".container-mechanic": {
        display: "grid",
        gridGap: ".5rem",
        [breakM]: {
          gridGap: "0",
        },
        gridTemplate: `
        " info img " 1fr
        / 1fr 1fr
        `,
        [breakM]: {
          gridTemplate: `
          " info " 1fr
          " img  " 1fr
          / 1fr 
          `,
        },
        ".info": {
          display: "grid",
          gridArea: "info",
          color: theme.colors.primary,
          background: theme.colors.backgroundLight,
          padding: "5rem",
          [breakS]: {
            padding: "2rem",
          },
          h1: {
            margin: 0,
            fontFamily: "title",
            fontSize: "7.2rem",
            [breakS]: {
              fontSize: "5rem",
            },
          },
          p: {
            maxWidth: "85%",
          },
        },
        img: {
          width: "100%",
          height: "100%",
          objectFit: "cover",
          gridArea: "img",
        },
        "&:nth-child(odd)": {
          ".info": {
            h1: {
              placeSelf: "end",
            },
            p: {
              placeSelf: "start end",
              textAlign: "end",
            },
          },
        },
        "&:nth-child(even)": {
          gridTemplate: `
            " img info " 1fr
            / 1fr 1fr
          `,
          [breakM]: {
            gridTemplate: `
            " info " 1fr
            " img  " 1fr
            / 1fr 
            `,
          },
          ".info": {
            h1: {
              placeSelf: "end start",
            },
            p: {
              placeSelf: "start",
              textAlign: "start",
            },
          },
        },
      },
    },
  });

export default getStyles;
