import { css } from "@emotion/react";
import { theme } from "../../../theme/default";
import { getBreakpoint } from "../../../utils/utils";

const breakM = getBreakpoint(theme.breakpoints.m);
const breakS = getBreakpoint(theme.breakpoints.s);

const getStyles = () =>
  css({
    width: "100%",
    height: "auto",
    display: "grid",
    placeItems: "start center",
    position: "relative",
    overflow: "hidden",
    ".draw-line": {
      height: "10rem",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridGap: ".5rem",
      [breakM]: {
        gridGap: "0",
      },
      background: theme.colors.primary,
      "&:before, &:after": {
        background: theme.colors.background,
        content: "''",
      },
    },
    ".round_background": {
      marginBottom: "10rem",
      position: "relative",
      display: "grid",
      placeItems: "center",
      color: theme.colors.white,
      border: `.5rem solid ${theme.colors.discordButton.border}`,
      background: theme.colors.background,
      boxShadow: `inset 0 0 1.5rem ${theme.colors.background}, inset 0 0 0 1.25rem ${theme.colors.background}, inset 0 0 0 2rem ${theme.colors.backgroundDark}`,
      transition: ".2s",
      "&:after, &:before": {
        content: "''",
        position: "absolute",
        width: "20vw",
        right: "calc(50% + 13rem)",
        borderTop: `.5rem solid ${theme.colors.primary}`,
        [breakS]: {
          right: "calc(50% + 8rem)",
        },
      },
      "&:before": {
        left: "calc(50% + 13rem)",
        borderRight: "1rem solid transparent",
        [breakS]: {
          left: "calc(50% + 8rem)",
        },
      },
      "&:after": {
        right: "calc(50% + 13rem)",
        borderLeft: "1rem solid transparent",
        [breakS]: {
          right: "calc(50% + 8rem)",
        },
      },
    },
  });

export default getStyles;
