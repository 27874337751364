import { css } from "@emotion/react";
import { theme } from "../../../theme/default";
import { getBreakpoint } from "../../../utils/utils";

const breakM = getBreakpoint(theme.breakpoints.m);

const getStyles = () =>
  css({
    width: "100%",
    maxWidth: "150rem",
    margin: "auto",
    marginTop: "10rem",
    display: "grid",
    placeItems: "center",
    gridTemplateColumns: "1fr 1fr",
    [breakM]: {
      gridTemplateColumns: "1fr",
    },
    iframe: {
      width: "80%",
      height: "30rem",
      [breakM]: {
        width: "90%",
      },
    },
    ".container-info": {
      display: "grid",
      gridTemplateRows: "1fr 1fr",
      placeItems: "center",
      padding: "5rem",
      gridGap: "5rem",
      img: {
        width: "100%",
      },
    },
  });

export default getStyles;
