/** @jsx jsx */
import React, { useState } from "react";
import { SerializedStyles, jsx } from "@emotion/react";
import getStyles from "./Footer.styles";
import { css } from "@emotion/react";
import { theme } from "../../../theme/default";

export const Footer = () => {
  const styles: SerializedStyles = getStyles();
  const [emailValue, setEmailValue] = useState<string>();

  // const icons: string[] = [
  //   theme.images.logos.discord,
  //   theme.images.logos.twitter,
  //   theme.images.logos.instagram,
  //   theme.images.logos.linkedin,
  //   theme.images.logos.mail,
  // ];

  const icons: any[] = [
    { src: theme.images.logos.discord, url: "https://discord.gg/tDMMThF5NG" },
    { src: theme.images.logos.twitter, url: "https://twitter.com/arrowfistgames" },
    { src: theme.images.logos.instagram, url: "https://www.instagram.com/arrowfistgames" },
    { src: theme.images.logos.linkedin, url: "https://www.linkedin.com/company/71492124" },
    { src: theme.images.logos.mail, url: "contact@arrowfistgames.com" },
  ];

  const onClickIcon = (url: string) => {
    return () => {
      window.open(url);
    };
  };

  const onInputChange = (e: any): void => {
    setEmailValue(e.target.value);
  };

  const onSubmit = (e: any): void => {
    e.preventDefault();
    console.log(emailValue);
  };

  return (
    <div css={styles} id="contact">
      <div className="footer">
        <img src={theme.images.logos.arrowfistWhite} />
        {/* <form onSubmit={onSubmit} className="form">
          <h1>
            Want to recibe <span>exclusive</span> content?
          </h1>
          <p>
            Write your email and recive exclusive <span>GIFTS</span> on
            Deflector's release date
          </p>
          <input
            id="mail"
            name="mail"
            value={emailValue}
            onChange={onInputChange}
          ></input>
          <button type="submit" className="gradient">
            Subscribe
          </button>
        </form> */}
        <div className="social">
          <h1>Follow us!</h1>
          <div>
            {icons.map((icon: any) => (
              <img onClick={onClickIcon(icon.url)} src={icon.src} />
            ))}
          </div>
        </div>
      </div>
      <div className="legal">
        <a>Terms of Use</a>
        <a>Privacy Policy</a>
      </div>
    </div>
  );
};
