import { Theme } from "./types";

// FONTS
import primaryFont from "./../resources/fonts/BAHNSCHRIF_Regular.TTF";
import title from "./../resources/fonts/BlackpastDemo-vm2l9.ttf";

// LOGOS
import logoWhite from "./../resources/img/ArrowfistGames_Logo_White.png";
import logoDiscord from "./../resources/img/Discord_Icon.png";
import logoDeflector from "./../resources/img/Deflector_Logo.png";
import logoArrowfist from "./../resources/img/ArrowfistGames_Logo.png";
import logoInstagram from "./../resources/img/Insta_Icon.png";
import logoTwitter from "./../resources/img/Twitter_Icon.png";
import logoLinkedn from "./../resources/img/Linkdn_Icon.png";
import logoMail from "./../resources/img/Email_Icon.png";

// MECHANICS
import deflect from "./../resources/img/Gallery/ss1.jpg";
import nonLinear from "./../resources/img/Gallery/ss6.jpg";
import combineSkills from "./../resources/img/Gallery/ss4.jpg";

// VIDEOS
import backgroundVideoLoop from "./../resources/video/WebVideoDeflector.mp4";

export const theme: Theme = {
  colors: {
    primary: "#e80e48",
    primary50: "#e80e4880",
    secondary: "#050714",
    backgroundLight: "#050714",
    background: "#050714",
    backgroundDark: "#160410",
    white: "#fff",
    black: "#000",
    header: {
      background: "#010208d9",
    },
    discordButton: {
      border: "#e80e48",
      background: "#533b41",
      shadow: "#e80e48",
      shadowDark: "#603741",
    },
  },
  fonts: {
    primary: primaryFont,
    title: title,
  },
  images: {
    logos: {
      arrowfistWhite: logoWhite,
      arrowfistBig: logoArrowfist,
      deflector: logoDeflector,
      discord: logoDiscord,
      instagram: logoInstagram,
      twitter: logoTwitter,
      linkedin: logoLinkedn,
      mail: logoMail,
    },
    mechanics: {
      deflect: deflect,
      nonLinear: nonLinear,
      combineSkills: combineSkills,
    },
  },
  videos: {
    backgroundVideoLoop: backgroundVideoLoop,
  },
  breakpoints: {
    s: "640px",
    m: "1024px",
    l: "2420px",
    xl: "4200px",
  },
};
