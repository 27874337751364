import ss1 from "./img/Gallery/ss1.jpg";
import ss2 from "./img/Gallery/ss2.jpg";
import ss3 from "./img/Gallery/ss3.jpg";
import ss4 from "./img/Gallery/ss4.jpg";
import ss5 from "./img/Gallery/ss5.jpg";

interface Data {
  galleryImages: string[];
}

const data: Data = {
  galleryImages: [
    ss1,
    ss2,
    ss3,
    ss4,
    ss5,
  ],
};

export default data;
