import { css } from "@emotion/react";
import { theme } from "../../theme/default";
import { getBreakpoint } from "../../utils/utils";

const styles = theme.colors.discordButton;
const breakS = getBreakpoint(theme.breakpoints.s);

const getStyles = () =>
  css({
    position: "fixed",
    bottom: "10rem",
    right: "10rem",
    cursor: "pointer",
    [breakS]: {
      bottom: "2rem",
      right: "2rem",
    },
    "&:hover": {
      boxShadow: `0 0 2rem ${styles.border}, inset 0 0 1.5rem ${styles.border}, inset 0 0 0 1.5rem ${styles.background}, inset 0 0 0 2.5rem ${styles.shadowDark}`,
      bottom: "10.5rem",
      img: {
        transform: "rotate(360deg)",
      },
    },
    img: {
      transition: ".4s ease-in-out",
      width: "7rem",
      [breakS]: {
        width: "5rem",
      }
    },
  });

export default getStyles;
