import { css } from "@emotion/react";
import { theme } from "./theme/default";

const getStyles = () =>
  css({
    width: "100%",
    height: "100%",
    overflow: "auto",
    "button.gradient": {
      border: `2px solid ${theme.colors.primary}`,
      color: "white",
      fontSize: "2.5rem",
      fontFamily: "title",
      padding: "1.5rem 3rem",
      background: `linear-gradient(to right, ${theme.colors.primary50}, black)`,
      cursor: "pointer",
    },
  });
export default getStyles;
